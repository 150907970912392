import api from "../../../../../services/api";
import Loading from "../../Loading";
import styles from "../../../../../styles/module/seachDatabase.module.css";
import { imagemURL, TipoPortal } from "../../../../../services/variables";
import { useQuery } from "react-query";

export default function SearchOffice(prop) {
  const wordSearch = prop.wordSearch;

  const { data, isLoading } = useQuery(
    `competencia-${wordSearch}`,
    async () => {
      const response = await api.get(`/search/cargos/${wordSearch}`);
      return response.data.res;
    },
    { cacheTime: 1000 * 60 * 60 * 1 }
  );

  return (
    <div>
      {data === undefined && !isLoading && (
        <p>Nenhuma Competência encontrada com este nome ou cargo.</p>
      )}
      {data && !isLoading && data !== undefined && (
        <div className={styles.container_competencia}>
          <h2>Competências Encontradas</h2>
          {!isLoading &&
            data &&
            data.map((item) => (
              <div className={styles.competencia} key={item.ID}>
                <img
                  src={imagemURL + item.IMG}
                  alt={item.NOME}
                  width={120}
                  height={120}
                />
                <span>
                  <h4>
                    {item.NOME} -{" "}
                    <a
                      href={
                        TipoPortal == 3
                          ? `/informacoes-institucionais/registro-das-competencias`
                          : `/vereadores/registro-das-competencias`
                      }
                    >
                      Ver registro
                    </a>
                  </h4>
                  <p>
                    Cargo: <b>{item.CARGO}</b>
                  </p>
                  <p>
                    E-mail: <b>{item.EMAIL}</b> - Telefone:{" "}
                    <b>{item.TELEFONE}</b>
                  </p>
                  <p>
                    Horário de Atendimento: <b>{item.ATENDIMENTO}</b>
                  </p>
                </span>
              </div>
            ))}
        </div>
      )}
      {isLoading && data !== undefined && <Loading />}
    </div>
  );
}
