import { useQuery } from "react-query";
import api from "../../../../../services/api";
import Loading from "../../Loading";
import styles from "../../../../../styles/module/seachDatabase.module.css";
import { imagemURL } from "../../../../../services/variables";

export default function SearchNews(prop) {
  const wordSearch = prop.wordSearch;

  const { data, isLoading } = useQuery(`noticias-${wordSearch}`, async () => {
    const response = await api.get(`/search/news/${wordSearch}`);
    return response.data.res;
  });

  return (
    <div>
      {data === undefined && !isLoading && data.length < 0 && (
        <p>Nenhuma noticia encontrada.</p>
      )}
      {data && !isLoading && data !== undefined && data.length > 0 && (
        <div className={styles.container_competencia}>
          <h2>Noticias Relacionadas</h2>
          {!isLoading &&
            data &&
            data.map((item) => (
              <div className={styles.competencia} key={item.SLUG}>
                <img
                  src={
                    item.IMG == "logo.jpg"
                      ? "/imagens/logo.png"
                      : imagemURL + item.IMG
                  }
                  alt={item.TITULO}
                  width={120}
                  height={120}
                />
                <span>
                  <h4>
                    {item.TITULO} - {converterData(item.DATA_POST)}
                  </h4>
                  <p>Categoria: {item.CATEGORIA}</p>
                  <a href={`/noticia/${item.SLUG}`}>Ver noticia</a>
                </span>
              </div>
            ))}
        </div>
      )}
      {isLoading && data !== undefined && <Loading />}
    </div>
  );
}

function converterData(dataString) {
  const data = new Date(dataString);
  data.setMinutes(data.getTimezoneOffset());
  return data.toLocaleDateString("pt-BR");
}
